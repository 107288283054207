<template>
  <layout v-load="loading">
    <b-card>
      <b-table
        hover
        :items="employees"
        :fields="fields"
      >
        <template #table-colgroup>
          <col :style="{ width: '108px' }"/>
          <col :style="{ width: '250px' }" />
          <col />
          <col />
        </template>
        <template #cell(photo)="data">
          <b-avatar
            size="2rem"
            :src="data.item.photo || AvatarDefault"
          />
        </template>
        <template #cell(is_send_feedback)="{item}">
          <i
            v-if="!item.is_send_feedback"
            class="bx bxs-info-circle text-danger font-size-20"
          />
        </template>
        <template #cell(actions)="{ item }">
          <b-button
            variant="primary"
            size="sm"
            @click="handleShowUserFeedbacks(item.uid)"
          >
            Show user feedbacks
          </b-button>
        </template>
      </b-table>
    </b-card>
  </layout>
</template>

<script>
import AvatarDefault from '@/assets/images/users/avatar-default.svg';

export default {
  name: 'GeneralDashboard',
  data() {
    return {
      AvatarDefault,
      loading: false,
      fields: [
        { key: 'photo', label: 'Avatar' },
        'name',
        { key: 'is_send_feedback', label: ''},
        { key: 'actions', label: '', tdClass: 'text-right' }
      ]
    }
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch('weeklyFeedback/fetchAllEmployees');
    this.loading = false;
  },
  methods: {
    handleShowUserFeedbacks(id) {
      this.$router.push({ name: 'weekly-feedback-user-dashboard', params: { id }});
    }
  },
  computed: {
    employees() {
      return this.$store.state.weeklyFeedback.employees;
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.table) td {
  vertical-align: middle;
}
</style>
